<template>
    <div class="grid grid-cols-12 gap-2">
        <ModalPeriodSelect
            :visible="modalPeriodSelectVisible"
            :client-id="clientId"
            :preselected-start-date="formData.date"
            :preselected-account-number="accrualSelectedAccount"
            :accrual-selected-amount="accrualSelectedAmount"
            @close="modalPeriodSelectVisible = false"
            @update="updateTransaction"
        />
        <div class="col-span-7 card-tail self-start">
            <el-form class="flex" ref="form" :model="formData" :rules="rules">
                <el-form-item prop="series">
                    <el-select ref="serieDropdown" v-model="formData.series" placeholder="Serie" class="w-full" filterable default-first-option>
                        <el-option v-for="series in seriesData" :key="series.prefix" :label="series.name + ' (' + series.prefix + ')'" :value="series.prefix" />
                    </el-select>
                </el-form-item>
                <el-form-item prop="date" class="ml-2">
                    <DateInputField ref="dateInputField" @change="formData.date = $event" />
                </el-form-item>
                <el-form-item prop="comment" class="ml-2">
                    <el-input v-model="formData.comment" placeholder="Beskrivning" />
                </el-form-item>
            </el-form>

            <!-- <p>Check if selected date is within the range of date picker</p> -->

            <VouchersTable
                :items-data="itemsData"
                :accounts-data="accountsData"
                :projects="projects"
                @newItemCreation="createNewItemComponent"
                @removeItem="removeItem"
                @updateValues="updateValues"
                @createVoucher="createVoucher"
                @showSelectPeriodModal="showSelectPeriodModal"
            />

            <div class="flex justify-end mt-3">
                <el-button type="primary" @click="createVoucher" :disabled="cantCreateVoucher">Spara</el-button>
            </div>

            <!-- <pre>{{ itemsData }}</pre> -->
        </div>
        <div class="col-span-5 card-tail">
            <FileUpload v-loading="$waiting.is('loading.files')" ref="fileUpload" :files-data="filesData" @filesSelected="filesSelected" />
        </div>
    </div>
</template>
<script>
import Api from "../lopande.api";

export default {
    components: {
        VouchersTable: () => import(/* webpackChunkName: "VouchersTable" */ "@/components/vouchersTable/VouchersTable.vue"),
        DateInputField: () => import(/* webpackChunkName: "DateInputField" */ "@/components/DateInputField.vue"),
        FileUpload: () => import(/* webpackChunkName: "VoucherNew/FileUpload" */ "./VoucherUploadFile.vue"),
        ModalPeriodSelect: () => import(/* webpackChunkName: "VoucherNew/VoucherNewAccrualModal" */ "./VoucherNewAccrualModal.vue"),
    },

    data() {
        return {
            projects: [],
            accountsData: [],
            seriesData: [],
            warningElements: [],
            filesData: [],
            modalPeriodSelectVisible: false,

            accrualSelectedIndex: 0,
            accrualSelectedAmount: 0,
            accrualSelectedAccount: null,

            formData: {
                series: "",
                date: "",
                comment: "",
            },
            itemsData: [
                {
                    id: 1,
                    account: null,
                    debit: 0,
                    credit: 0,
                    text: "",
                },
            ],
            rules: {
                series: [{ required: true, message: "Required", trigger: "blur" }],
                date: [
                    { required: true, message: "Datum saknas", trigger: "blur" },
                    { required: true, pattern: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/, message: "Felaktigt datum (åååå-mm-dd)", trigger: "blur" },
                ],
                comment: [{ required: true, message: "Required", trigger: "blur" }],
            },
        };
    },

    computed: {
        debitSummary() {
            const summary = this.itemsData.reduce((total, amount) => {
                const result = total + amount.debit;
                return result.roundToEven2();
            }, 0);
            return summary;
        },

        creditSummary() {
            const summary = this.itemsData.reduce((total, amount) => {
                const result = total + amount.credit;
                return result.roundToEven2();
            }, 0);
            return summary;
        },

        cantCreateVoucher() {
            return this.debitSummary === 0 || this.creditSummary === 0 || this.debitSummary !== this.creditSummary || !this.formData.series || !this.formData.date || !this.formData.comment;
        },
    },

    async created() {
        this.getAccounts();
        this.getSeries();
        const data = await Api.getProjects(this.$route.params.clientId);
        console.log("Data fetch", data);
        this.projects = data;
        console.log("Projects fetch", data);
    },

    props: {
        query: {
            type: Object,
        },
        clientId: {
            type: String,
            default: "",
        },
    },

    methods: {
        filesSelected(files) {
            this.filesData = files;
        },

        async getAccounts() {
            this.accountsData = await Api.getAccounts(this.clientId);
        },

        async getSeries() {
            this.seriesData = await Api.getSeries();
            this.$refs.serieDropdown.focus();
        },

        createNewItemComponent(item) {
            this.itemsData.push(item);
        },

        removeItem(index) {
            let newItems = this.itemsData;
            const lastRow = index === newItems.length - 1;
            newItems.splice(index, 1);
            this.itemsData = newItems;

            if (this.itemsData.filter(item => !item.readOnly).length === 0 || lastRow) {
                this.itemsData.push({
                    id: Math.random(),
                    account: null,
                    debit: 0,
                    credit: 0,
                    text: "",
                });
            }
        },

        updateValues({ index, account, debit, credit, comment }) {
            this.itemsData[index].account = account;
            this.itemsData[index].debit = debit;
            this.itemsData[index].credit = credit;
            this.itemsData[index].text = comment;
        },

        async createVoucher() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            this.warningElements = this.itemsData.filter(item => item.debit === 0 && item.credit === 0).map(item => item.id);

            const confirm = await this.$dialog.title("Bekräftelse").confirm("Är du säker?");
            if (!confirm) return;

            const response = await Api.createVoucher(this.clientId, {
                serie: this.formData.series,
                date: this.formData.date,
                text: this.formData.comment,
                transactions: this.itemsData.map(item => ({
                    text: item.text,
                    debit: item.debit,
                    credit: item.credit,
                    account: item.account,
                    costAccount: item.costAccount || null,
                    accrual: item.accrual || false,
                    startDate: item.startDate || null,
                    endDate: item.endDate || null,
                })),
            });

            if (response.status !== 200) {
                this.$notify.error({ title: "Error", message: response.message });
                return;
            }

            this.uploadFiles(response);

            this.$notify.success({ title: "Utfört" });

            this.resetForm();
        },

        async uploadFiles(response) {
            this.filesData.forEach(async file => {
                const fileType = {
                    "application/pdf": 1,
                    "image/jpeg": 4,
                    "image/png": 5,
                };

                await Api.uploadFile({
                    clientId: response.data.clientId,
                    voucherId: response.data.voucherId,
                    fileContent: await this.toBase64(file.raw),
                    fileType: fileType[file.raw.type],
                    fileName: file.name,
                });
            });
        },

        async toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result.split(",").pop());
                reader.onerror = error => reject(error);
            });
        },

        async resetForm() {
            this.$refs.dateInputField.reset();
            this.$refs.fileUpload.reset();
            this.warningElements = [];
            this.filesData = [];
            this.formData = {
                series: "",
                date: "",
                comment: "",
            };
            this.itemsData = [];
            await this.$nextTick();
            this.itemsData = [
                {
                    id: 1,
                    account: null,
                    debit: 0,
                    credit: 0,
                    text: "",
                },
            ];
        },

        showSelectPeriodModal({ index, amount, account }) {
            this.accrualSelectedIndex = index;
            this.accrualSelectedAmount = amount;
            this.accrualSelectedAccount = account;
            this.modalPeriodSelectVisible = true;
        },

        async updateTransaction(event) {
            const tempData = JSON.parse(JSON.stringify(this.itemsData));
            tempData[this.accrualSelectedIndex].costAccount = tempData[this.accrualSelectedIndex].account;
            tempData[this.accrualSelectedIndex].account = event.interimAccount;
            tempData[this.accrualSelectedIndex].text = event.transactionText;
            tempData[this.accrualSelectedIndex].startDate = event.startDate;
            tempData[this.accrualSelectedIndex].endDate = event.endDate;
            tempData[this.accrualSelectedIndex].accrual = true;
            this.itemsData = [];
            await this.$nextTick();
            this.itemsData = tempData;
        },
    },
};
</script>
<style scoped>
table td {
    border: 1px solid #ddd;
}
</style>
